import axios from "axios";

// 创建 axios 实例
const apiClient = axios.create({
  // baseURL: "http://0.0.0.0:5000/api", // 使用代理路径
  baseURL: "https://bcbpd2.sch401.xyz/api", // 使用代理路径
  headers: {
    "Content-Type": "application/json",
  },
});

export default {
  // 获取选项（Category）
  async getOptions() {
    try {
      const response = await apiClient.get("/options"); // 去掉多余的 /api
      return response.data;
    } catch (error) {
      console.error("Error fetching options:", error);
      throw error;
    }
  },

  // 根据 category 获取 common_name 列表
  async getCommonNames(category) {
    try {
      const response = await apiClient.get("/common-names", {
        params: { category },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching common names:", error);
      throw error;
    }
  },

  // 根据 common_name 获取 latin_name 列表
  async getLatinNames(common_name) {
    try {
      const response = await apiClient.get("/latin-names", {
        params: { common_name },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching latin names:", error);
      throw error;
    }
  },

  // 根据 latin_name 获取 option_one 列表
  async getOptionOnes(latin_name) {
    try {
      const response = await apiClient.get("/option-ones", {
        params: { latin_name },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching option ones:", error);
      throw error;
    }
  },

  // 根据条件查询数据
  async searchData(params) {
    try {
      const response = await apiClient.post("/search", params);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  // 获取甲烷产量数据
  async getMethaneProduction(id) {
    try {
      const response = await apiClient.get(`/methane-production/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching methane production data:", error);
      throw error;
    }
  },

  // 预测甲烷产量
  async predictMethaneYield(data) {
    try {
      const response = await apiClient.post("/predict", data);
      return response.data;
    } catch (error) {
      console.error("Error predicting methane yield:", error);
      throw error;
    }
  },
};
