<template>
  <nav class="navbar">
    <div class="navbar-container">
      <div class="navbar-logo">
        <img src="/images/buct.png" alt="Logo 1" class="logo logo-1"/>
      </div>
      <div class="navbar-links">
        <router-link to="/">Home</router-link>
        <router-link to="/data-query">Data Query</router-link>
        <router-link to="/data-predict">Data Predict</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
};
</script>

<style scoped>
.navbar {
  background: #2c3e50;
  color: white;
  padding: 5px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between; /* 默认两端对齐 */
  align-items: center; /* 垂直居中 */
}

.navbar-logo {
  display: flex;
  align-items: center; /* 确保logo垂直居中 */
}

.logo {
  height: 40px; /* 根据需要调整logo的高度 */
  width: auto;  /* 保持宽高比 */
}

.navbar-links {
  display: flex;
  gap: 20px; /* 链接间的间距 */
  flex-grow: 1; /* 占用剩余空间 */
  justify-content: center; /* 居中对齐链接 */
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 4px;
}

.navbar-links a:hover {
  color: #42b983;
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-links a.router-link-exact-active {
  color: #42b983;
  background-color: rgba(255, 255, 255, 0.1);
}
</style>