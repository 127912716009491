// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue'; // 确保路径正确
import DataQuery from '../views/DataQuery.vue';
import DataCompare from '../views/DataCompare.vue';
import DataPredict from '../views/DataPredict.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/data-query',
    name: 'DataQuery',
    component: DataQuery,
  },
  {
    path: '/data-compare',
    name: 'DataCompare',
    component: DataCompare,
  },
  {
    path: '/data-predict',
    name: 'DataPredict',
    component: DataPredict,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;