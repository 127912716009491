<template>
  <div class="data-query">
    <h1>Data Query</h1>

    <!-- 搜索表单 -->
    <div class="search-form">
      <label for="category">Category:</label>
      <select id="category" v-model="searchParams.category" @change="loadCommonNames">
        <option value="">Select Category</option>
        <option v-for="category in categories" :key="category" :value="category">
          {{ category }}
        </option>
      </select>

      <label for="common_name">Common Name:</label>
      <select id="common_name" v-model="searchParams.common_name" @change="loadLatinNames">
        <option value="">Select Common Name</option>
        <option v-for="name in commonNames" :key="name" :value="name">
          {{ name }}
        </option>
      </select>

      <label for="latin_name">Latin Name:</label>
      <select id="latin_name" v-model="searchParams.latin_name" @change="loadOptionOnes">
        <option value="">Select Latin Name</option>
        <option v-for="name in latinNames" :key="name" :value="name">
          {{ name }}
        </option>
      </select>

      <label for="option_one">Option:</label>
      <select id="option_one" v-model="searchParams.option_one">
        <option value="">Select Option</option>
        <option v-for="option in options" :key="option" :value="option">
          {{ option }}
        </option>
      </select>

      <button @click="searchData" :disabled="isSearching">Search</button>
    </div>

    <!-- 结果展示 -->
    <div v-if="results && results.length > 0" class="results">
      <h2>Search Results</h2>
      <div class="results-grid">
        <div v-for="(item, index) in results" :key="index" class="result-item">
          <!-- 基本信息 -->
          <div class="result-row">
            <div class="result-field">
              <div class="result-label">Category:</div>
              <div class="result-value">{{ item.category }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">Common Name:</div>
              <div class="result-value">{{ item.common_name }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">Latin Name:</div>
              <div class="result-value">{{ item.latin_name }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">Option:</div>
              <div class="result-value">{{ item.option_one }}</div>
            </div>
          </div>

          <!-- 核心数据 -->
          <div class="result-row">
            <div class="result-field">
              <div class="result-label">TS (%):</div>
              <div class="result-value">{{ item.ts }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">VS (%):</div>
              <div class="result-value">{{ item.vs }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">VS/TS:</div>
              <div class="result-value">{{ item.vs_div_ts }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">Index O:</div>
              <div class="result-value">{{ item.index_o }}</div>
            </div>
          </div>

          <!-- 其他指标 -->
          <div class="result-row">
            <div class="result-field">
              <div class="result-label">Index C:</div>
              <div class="result-value">{{ item.index_c }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">Index H:</div>
              <div class="result-value">{{ item.index_h }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">Index N:</div>
              <div class="result-value">{{ item.index_n }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">Cellulose Content:</div>
              <div class="result-value">{{ item.cellulose_content }}</div>
            </div>
          </div>

          <!-- 化学成分 -->
          <div class="result-row">
            <div class="result-field">
              <div class="result-label">Hemicellulose Content:</div>
              <div class="result-value">{{ item.hemicellulose_content }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">Lignin Content:</div>
              <div class="result-value">{{ item.lignin_content }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">Non-Lignocellulosic Organic Content:</div>
              <div class="result-value">{{ item.non_lignocellulosic_organic_content }}</div>
            </div>
            <div class="result-field">
              <div class="result-label">Non-Structural Carbohydrate:</div>
              <div class="result-value">{{ item.non_structural_carbohydrate }}</div>
            </div>
          </div>

          <!-- 甲烷产量图表 -->
          <div class="chart-container">
            <h3>{{ item.common_name }} - Methane Production Over Time</h3>
            <div :ref="el => setChartRef(el, item.id)" style="width: 100%; height: 400px;"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 无结果提示 -->
    <div v-else-if="searchPerformed" class="no-results">
      <p>No results found.</p>
    </div>
  </div>
</template>


---

### **脚本部分**

```javascript
<script>
import api from "@/api";
import * as echarts from "echarts";
import debounce from "lodash/debounce";

export default {
  name: "DataQuery",
  data() {
    return {
      isSearching: false,
      searchParams: {
        category: "",
        common_name: "",
        latin_name: "",
        option_one: "",
      },
      categories: [],
      commonNames: [],
      latinNames: [],
      options: [],
      results: [],
      searchPerformed: false,
      chartInstances: {},
      chartRefs: {},
    };
  },
  methods: {
    async loadCategories() {
      try {
        const options = await api.getOptions();
        this.categories = options.categories;
      } catch (error) {
        console.error("Error loading categories:", error);
      }
    },
    async loadCommonNames() {
      if (!this.searchParams.category) return;
      try {
        const response = await api.getCommonNames(this.searchParams.category);
        this.commonNames = response.commonNames;
        this.latinNames = [];
        this.options = [];
      } catch (error) {
        console.error("Error loading common names:", error);
      }
    },
    async loadLatinNames() {
      if (!this.searchParams.common_name) return;
      try {
        const response = await api.getLatinNames(this.searchParams.common_name);
        this.latinNames = response.latinNames;
        this.options = [];
      } catch (error) {
        console.error("Error loading latin names:", error);
      }
    },
    async loadOptionOnes() {
      if (!this.searchParams.latin_name) return;
      try {
        const response = await api.getOptionOnes(this.searchParams.latin_name);
        this.options = response.options;
      } catch (error) {
        console.error("Error loading options:", error);
      }
    },
    searchData: debounce(async function () {
      if (this.isSearching) return;
      this.isSearching = true;

      try {
        const response = await api.searchData(this.searchParams);
        this.results = response.data || [];
        this.searchPerformed = true;

        // 清除旧的图表实例
        Object.values(this.chartInstances).forEach((chart) => {
          if (chart) chart.dispose();
        });
        this.chartInstances = {};

        // 等待 DOM 更新后绘制图表
        await this.$nextTick();
        this.results.forEach((item) => {
          this.drawMethaneChart(item.id);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        this.results = [];
        this.searchPerformed = true;
      } finally {
        this.isSearching = false;
      }
    }, 300),
    setChartRef(el, id) {
      if (el) this.chartRefs[id] = el;
    },
    async drawMethaneChart(id) {
      try {
        const response = await api.getMethaneProduction(id);
        const methaneData = response.methane_production;

        const container = this.chartRefs[id];
        if (!container) {
          console.error("Chart container not found for ID:", id);
          return;
        }

        const chartInstance = echarts.init(container);
        const options = {
          title: {
            left: "center",
            textStyle: {
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: { type: "cross" },
          },
          xAxis: {
            type: "category",
            data: Object.keys(methaneData),
            name: "Days",
            nameLocation: "middle", // 横坐标轴标题居中
            nameGap: 25,            // 调整坐标轴名称和坐标轴线之间的距离
          },
          yAxis: {
            type: "value",
            name: "Methane Production (mL/g VS)",
            nameLocation: "middle", // 纵坐标轴标题居中
            nameRotate: 90,         // 让纵坐标轴标题与坐标轴平行，垂直于横坐标
            nameGap: 30,            // 调整坐标轴名称和坐标轴线之间的距离
          },
          series: [
            {
              name: "Methane Production (mL/g VS)",
              type: "line",
              smooth: true,
              data: Object.values(methaneData),
              lineStyle: { color: "rgba(75, 192, 192, 1)", width: 2 },
              areaStyle: { color: "rgba(75, 192, 192, 0.2)" },
            },
          ],
        };

        chartInstance.setOption(options);
        this.chartInstances[id] = chartInstance;

        chartInstance.setOption(options);
        this.chartInstances[id] = chartInstance;
      } catch (error) {
        console.error("Error drawing chart:", error);
      }
    },
  },
  async created() {
    await this.loadCategories();
  },
  beforeUnmount() {
    Object.values(this.chartInstances).forEach((chartInstance) => {
      if (chartInstance) {
        chartInstance.dispose();
      }
    });
    this.chartInstances = {};
  },
};
</script>


<style scoped>
.data-query {
  padding: 20px;
  background-color: #f9fafb;
  font-family: 'Arial', sans-serif;
  color: #2c3e50;
}

h1 {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 20px;
}

.search-form {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.search-form label {
  font-weight: bold;
  color: #2c3e50;
}

.search-form select,
.search-form button {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background-color: #fff;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.search-form select {
  flex: 1;
  min-width: 150px;
}

.search-form button {
  background-color: #42b983;
  color: white;
  cursor: pointer;
  border: none;
}

.search-form button:hover {
  background-color: #3aa876;
}

.results {
  margin-top: 20px;
}

.results-grid {
  display: grid;
  gap: 20px;
}

.result-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.result-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  padding: 8px 0;
  border-bottom: 1px solid #e5e7eb;
}

.result-row:last-child {
  border-bottom: none;
}

.result-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.result-label {
  font-weight: bold;
  color: #2c3e50;
}

.result-value {
  color: #6b7280;
}

.no-results {
  margin-top: 20px;
  color: #6b7280;
  text-align: center;
}

.chart-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.doi-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

canvas {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .result-row {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}
</style>