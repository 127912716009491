<template>
  <div class="home">
    <!-- 页面内容 -->
    <fluent-card class="content-card">
      <h1 class="title">Biomass Characteristics and Biomethane Production Database（BCBDP）</h1>
      <p class="description">
        Lignocellulosic waste is one of the most abundant biowaste produced from agriculture, forestry and urban life.
        It can be converted into methane through anaerobic digestion. However, the physical and chemical characteristics
        of various LWs can be quite different, resulting in different energy conversion efficiency. The biochemical
        methane potential test is a standard method to determine the maximum methane yield of a feedstock. BCBPD
        includes 157 lignocellulosic wastes in terms of the physicochemical characteristics and methane production
        performance. Besides, a data-driven approach is also built for predicting methane production of LW.
      </p>

      <p class="description">
        The BCBPD is an integrated platform for:
      </p>

      <ul class="feature-list left-aligned">
        <li>Searching and comparing the basic characteristics and methane production performance of 157 lignocellulosic
          wastes (to be continuously updated).</li>
        <li>Predicting the methane production of LW not collected in the BCBPD using machine learning model built by
          TPOT.</li>
      </ul>

      <p class="description">
        We appreciate and welcome all the uses and citations of this database. If you have any suggestions or would like
        to share new data, please contact Prof. Chang Chen by mailing to <a href="mailto:chenchang@mail.buct.edu.cn"
          class="email-link">chenchang@mail.buct.edu.cn</a>.
      </p>

      <div class="citation">
        <p class="citation-text">Please cite this work if this BCBPD is adopted in scientific paper:</p>
        <p class="citation-content">
          <a href="https://doi.org/10.1016/j.biortech.2023.129953" target="_blank" rel="noopener noreferrer">
            <em>Song, C., Cai, F., Yang, S., Wang, L., Liu, G., & Chen, C. (2024). Machine learning-based prediction of
              methane production from lignocellulosic wastes. Bioresource Technology, 393, 129953.</em>
          </a>
        </p>
      </div>

      <footer class="site-footer">
        <div class="footer">
          <p>京ICP备2022003999号</p>
          <p>京公网安备 11010502051410号</p>
        </div>
      </footer>
    </fluent-card>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
.home {
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  min-height: 100vh;
}

.content-card {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 40px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 1.5rem;
  color: #2c3e50;
  text-align: left;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  text-align: left;
  margin-bottom: 20px;
}

.feature-list.left-aligned {
  margin-left: 20px;
  list-style-type: disc;
  margin-bottom: 20px;
  text-align: left;
}

.feature-list li {
  margin-bottom: 10px;
}

.email-link {
  color: #42b983;
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}

.citation {
  margin-top: 30px;
}

.citation-text {
  font-size: 0.9rem;
  color: #666;
  text-align: left;
  /* 靠左对齐 */
  margin-bottom: 10px;
}

.citation-content {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  /* 居中对齐 */
  margin: 0 auto;
  max-width: 80%;
  /* 控制引用内容的宽度 */
}

.footer {
  font-size: 0.8rem;
  line-height: 1.6;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}
</style>