<template>
  <div class="data-predict">
    <h1>Data Predict</h1>

    <!-- 输入表单 -->
    <div class="input-form">
      <!-- 使用flexbox创建两列布局 -->
      <div class="input-columns">
        <div class="input-column">
          <div class="input-field">
            <label for="ts">TS (%):</label>
            <input id="ts" v-model="inputData.ts" type="number" />
          </div>
          <div class="input-field">
            <label for="vs">VS (%):</label>
            <input id="vs" v-model="inputData.vs" type="number" />
          </div>
          <div class="input-field">
            <label for="c">C (%):</label>
            <input id="c" v-model="inputData.c" type="number" />
          </div>
          <div class="input-field">
            <label for="o">O (%):</label>
            <input id="o" v-model="inputData.o" type="number" />
          </div>
          <div class="input-field">
            <label for="h">H (%):</label>
            <input id="h" v-model="inputData.h" type="number" />
          </div>
          <div class="input-field">
            <label for="n">N (%):</label>
            <input id="n" v-model="inputData.n" type="number" />
          </div>
        </div>
        <div class="input-column">
          <div class="input-field">
            <label for="vs_div_ts">VS/TS:</label>
            <input id="vs_div_ts" v-model="inputData.vs_div_ts" type="number" />
          </div>
          <div class="input-field">
            <label for="cc">Cellulose Content (%):</label>
            <input id="cc" v-model="inputData.cc" type="number" />
          </div>
          <div class="input-field">
            <label for="hc">Hemicellulose Content (%):</label>
            <input id="hc" v-model="inputData.hc" type="number" />
          </div>
          <div class="input-field">
            <label for="lc">Lignin Content (%):</label>
            <input id="lc" v-model="inputData.lc" type="number" />
          </div>
          <div class="input-field">
            <label for="dt">Digestion Time (Days):</label>
            <input id="dt" v-model="inputData.dt" type="number" />
          </div>
          <div class="input-field">
            <label for="noc">Non-Lignocellulosic Organic Content (%):</label>
            <input id="noc" v-model="inputData.noc" type="number" />
          </div>
          <div class="input-field">
            <label for="ol">Organic Load (g VS/L):</label>
            <input id="ol" v-model="inputData.ol" type="number" />
          </div>
          <div class="input-field">
            <label for="si">Substrate/Inoculum Ratio:</label>
            <input id="si" v-model="inputData.si" type="number" />
          </div>
        </div>
      </div>

      <!-- 让按钮宽度自适应 -->
      <button @click="predict" :disabled="isPredicting" class="predict-button">Predict</button>
    </div>

    <!-- 显示预测结果的图表 -->
    <div class="results" v-if="predictionResults">
      <h2>Prediction Results</h2>
      <div id="chart" style="width: 100%; height: 400px;"></div> <!-- 图表容器 -->
    </div>

    <!-- 加载状态 -->
    <div v-if="isPredicting" class="loading">
      <p>Predicting...</p>
    </div>

    <!-- 错误提示 -->
    <div v-if="error" class="error">
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"; // 引入 ECharts
import api from "@/api"; // 引入 API

export default {
  name: "DataPredict",
  data() {
    return {
      inputData: {
        ts: null,
        vs: null,
        vs_div_ts: null,
        c: null,
        o: null,
        h: null,
        n: null,
        cc: null,
        hc: null,
        lc: null,
        dt: null,
        noc: null,
        ol: null,
        si: null,
      },
      predictionResults: null, // 预测结果
      isPredicting: false, // 是否正在加载
      error: null, // 错误信息
      chartInstance: null, // ECharts 实例
    };
  },
  methods: {
    async predict() {
      if (this.isPredicting) return; // 防止重复点击
      this.isPredicting = true;
      this.error = null;

      try {
        console.log("Sending request with data:", this.inputData); // 打印发送的数据
        const response = await api.predictMethaneYield({ ...this.inputData }); // 调用 API
        console.log("Received response:", response); // 打印接收的数据
        this.predictionResults = response; // 保存预测结果

        // 在更新 DOM 后再渲染图表
        this.$nextTick(() => {
          this.renderChart(); // 渲染图表
        });
      } catch (error) {
        console.error("Error predicting methane yield:", error);
        this.error = "Failed to predict methane yield. Please try again.";
      } finally {
        this.isPredicting = false;
      }
    },
    renderChart() {
      if (
        !this.predictionResults ||
        !this.predictionResults.CMY ||
        !this.predictionResults.time
      ) {
        console.error("Insufficient data to render chart.");
        return;
      }

      const chartDom = document.getElementById("chart");
      this.chartInstance = echarts.init(chartDom, null, { renderer: 'canvas', pixelRatio: 2 }); const options = {
        title: {
          left: "center",
          textStyle: {
            fontSize: 20,
            fontWeight: "bold",
            color: "#2c3e50",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(50, 50, 50, 0.8)",
          borderColor: "#ccc",
          borderWidth: 1,
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["Methane Production (mL/g VS)"],
          top: 30,
          textStyle: {
            fontSize: 14,
            color: "#333",
          },
        },
        grid: {
          top: 80,
          left: "10%",
          right: "10%",
          bottom: "15%",
        },
        xAxis: {
          type: "category",
          data: this.predictionResults.time, // 时间轴数据
          name: "Days",
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: 16,
            padding: 15,
          },
          axisLine: {
            lineStyle: {
              color: "#999",
            },
          },
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
          },
        },
        yAxis: {
          type: "value",
          name: "Methane Production (mL/g VS)",
          nameLocation: "middle",
          nameRotate: 90,
          nameTextStyle: {
            fontSize: 16,
            padding: 50,
          },
          axisLine: {
            lineStyle: {
              color: "#999",
            },
          },
          axisLabel: {
            fontSize: 12,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#ddd",
            },
          },
        },
        series: [
          {
            name: "Methane Production (mL/g VS)",
            type: "line",
            smooth: true,
            data: this.predictionResults.CMY, // 数据值
            lineStyle: {
              color: "rgba(75, 192, 192, 1)", // 线条颜色
              width: 3,
            },
            itemStyle: {
              color: "rgba(75, 192, 192, 1)", // 数据点颜色
            },
            areaStyle: {
              color: "rgba(75, 192, 192, 0.2)", // 区域填充颜色
            },
          },
        ],
      };

      this.chartInstance.setOption(options);
    }
  },
  beforeUnmount() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  }
};
</script>

<style scoped>
.data-predict {
  padding: 20px;
  background-color: #f9fafb;
  font-family: "Arial", sans-serif;
  color: #2c3e50;
}

h1 {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 20px;
}

.input-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  text-align: center;
  /* 添加这一行 */
}

/* 设置两列布局 */
.input-columns {
  display: flex;
  justify-content: space-between;
  /* 分隔两个列 */
}

.input-column {
  flex: 1;
  margin-right: 20px;
  /* 右边距用于分隔两列 */
}

.input-column:last-child {
  margin-right: 0;
  /* 移除最后一列的右边距 */
}

.input-field {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.input-field label {
  font-weight: bold;
  min-width: 150px;
}

.input-field input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  font-size: 1rem;
  flex-grow: 1;
}

.predict-button {
  padding: 10px 20px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.predict-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.results {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  min-height: 400px;
  /* 确保有足够的高度 */
}

#chart {
  width: 80%;
  /* 设置一个适当的宽度 */
  height: 400px;
  /* 固定高度 */
}

.loading,
.error {
  margin-top: 20px;
  text-align: center;
}

.error {
  color: red;
}
</style>