import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { provideFluentDesignSystem, fluentButton, fluentCard } from '@fluentui/web-components';

// 注册 Fluent Design 组件
provideFluentDesignSystem().register(fluentButton(), fluentCard());

// 创建 Vue 应用
const app = createApp(App);
app.use(router);
app.mount('#app');