<template>
  <div class="data-compare">
    <h1>Data Compare</h1>
    <p>This is the data compare page.</p>
  </div>
</template>

<script>
export default {
  name: 'DataCompare',
};
</script>

<style scoped>
.data-compare {
  padding: 20px;
}
</style>